export const menuOptions = [
    {
        "_id": 0,
        "value": "food",
        "viewValue": "Food",
        "options": [
            {
                "subMenu": "starters",
                "meals": [
                    {
                        "name": "BUTTERNUT SQUASH SOUP",
                        "info": "goat cheese sprinkle",
                        "cost": 100,
                        "id": 0
                    },
                    {
                        "name": "crunchy chicken",
                        "info": "breaded crust, sweet chili dip",
                        "cost": 125,
                        "id": 1
                    },
                    {
                        "name": "beet humus",
                        "info": "Classic chickpea & beetroot dip",
                        "cost": 110,
                        "id": 2
                    },
                    {
                        "name": "barbecue wings",
                        "info": "Chicken wings, house-made barbecue sauce",
                        "cost": 145,
                        "id": 3
                    },
                    {
                        "name": "Spinach artichoke dip",
                        "info": "Spinach, artichoke, mix of cheese, tortilla chips",
                        "cost": 195,
                        "id": 4
                    },
                    {
                        "name": "fried brie bites",
                        "info": "Breaded Brie cheese, Rosemary crust, Blueberry chutney",
                        "cost": 250,
                        "id": 5
                    },
                    {
                        "name": "fried calamari",
                        "info": "green chili, tartar dip",
                        "cost": 200,
                        "id": 6
                    },
                    {
                        "name": "OCTOPUS À LA PROVENÇALE",
                        "info": "green chili, tartar dip",
                        "cost": 190,
                        "id": 7
                    },
                    {
                        "name": "shrimp skewers",
                        "info": "Shrimps, Coconut dip",
                        "cost": 160,
                        "id": 8
                    },
                    {
                        "name": "LEMON GAMBERI",
                        "info": "Shrimps, lemon ginger butter",
                        "cost": 165,
                        "id": 9
                    },
                    {
                        "name": "grilled octopus",
                        "info": "Smoked Paprika, mashed potatoes",
                        "cost": 195,
                        "id": 10
                    },
                    {
                        "name": "beef carnitas tacos",
                        "info": "Pineapple, Pico de Gallo",
                        "cost": 165,
                        "id": 11
                    }
                ],
                "id": 0
            },
            {
                "subMenu": "gourmet burgers",
                "meals": [
                    {
                        "name": "The original",
                        "info": "200g Beef patty, lettuce, tomato, onions, pickles, mature cheddar, classic sauce",
                        "cost": 220,
                        "id": 0
                    },
                    {
                        "name": "the chicken",
                        "info": "Grilled chicken, lettuce, pickles, mature cheddar, onion rings, house-made barbecue sauce",
                        "cost": 195,
                        "id": 1
                    },
                    {
                        "name": "the fish",
                        "info": "Breaded Grouper fillet, Lettuce, Tartar sauce",
                        "cost": 175,
                        "id": 2
                    },
                    {
                        "name": "the tex-mex",
                        "info": "Bourbon Whiskey, angostura bitters & brown sugar.",
                        "cost": 80,
                        "isNew": true,
                        "id": 3
                    },
                    {
                        "name": "the brie",
                        "info": "200g Beef patty, Brie cheese, fresh mushrooms, lettuce, pickles, brie sauce",
                        "cost": 240,
                        "id": 4
                    }
                ],
                "id": 1
            },
            {
                "subMenu": "salads",
                "meals": [
                    {
                        "name": "caesar",
                        "info": "Romaine, grated reggiano, croutons, Caesar dressing (chicken +45, Shrimps +65)",
                        "cost": 175,
                        "id": 0
                    },
                    {
                        "name": "quinoa",
                        "info": "beetroot, arugula, cherry tomato, cucumber, dried cranberries, white balsamic dressing. (add chicken +45)",
                        "cost": 185,
                        "id": 1
                    },
                    {
                        "name": "fattoush",
                        "info": "Mixed greens, cherry tomato, cucumber, mint, fresh thyme, radish",
                        "cost": 150,
                        "id": 2
                    },
                    {
                        "name": "goat cheese",
                        "info": "Mixed greens, goat cheese, apple, walnut, dried cranberries, Honey balsamic vinaigrette",
                        "cost": 230,
                        "id": 3
                    },
                    {
                        "name": "asian",
                        "info": "Rice vermicelli, crab, cucumber, carrot, Zucchini, Sesame seed, Soy ginger dressing",
                        "cost": 190,
                        "id": 4
                    },
                    {
                        "name": "chicken kale",
                        "info": "Kale, Quinoa, peanuts, dried apricot, sesame balsamic dressing",
                        "cost": 220,
                        "id": 5
                    },
                    {
                        "name": "beef mango",
                        "info": "Mixed greens, beef strips, avocado, mango, cherry tomato, Mango dressing",
                        "cost": 245,
                        "id": 6
                    },
                    {
                        "name": "cobb",
                        "info": "Mixed greens, grilled chicken, cherry tomato, cucumber, avocado, crispy bacon, hardboiled egg, blue cheese, balsamic vinaigrette",
                        "cost": 220,
                        "id": 7
                    },
                    {
                        "name": "summer",
                        "info": "Mixed greens, Shrimps, orange, grapefruit, cherry tomato, cucumber, carrot, tropical dressing",
                        "cost": 220,
                        "id": 8
                    }
                ],
                "id": 2
            },
            {
                "subMenu": "sandwiches (lunch only)",
                "meals": [
                    {
                        "name": "Tfalafel wrap",
                        "info": "Flour tortilla, chickpeas, fava Beans, tomato, arugula, radish, spicy sesame sauce",
                        "cost": 155,
                        "id": 0
                    },
                    {
                        "name": "Chicken avocado",
                        "info": "Grilled chicken, avocado, lettuce, tomato, honey mustard",
                        "cost": 165,
                        "id": 1
                    },
                    {
                        "name": "steak mushroom",
                        "info": "beef strips, arugula, fresh mushroom, A l’anciènne sauce",
                        "cost": 195,
                        "id": 2
                    }
                ],
                "id": 3
            },
            {
                "subMenu": "from the land",
                "meals": [
                    {
                        "name": "Tommy's magarita",
                        "info": "100% Agave blanco tequila, fresh lime, agave nectar",
                        "cost": 80,
                        "id": 0
                    },
                    {
                        "name": "Caipirinha",
                        "info": "Cachaça, brown sugar, fresh lime",
                        "cost": 80,
                        "id": 1
                    },
                    {
                        "name": "mai tai",
                        "info": "White rum, dark rum, apricot brandy, orange juice, lemon juice, lime syrup",
                        "cost": 80,
                        "id": 2
                    },
                    {
                        "name": "Old fashioned",
                        "info": "Bourbon Whiskey, angostura bitters & brown sugar.",
                        "cost": 80,
                        "id": 3
                    },
                    {
                        "name": "Valentino",
                        "info": "Vodka, watermelon liqueur, basil & cucumber",
                        "cost": 80,
                        "id": 4
                    },
                    {
                        "name": "gin basil",
                        "info": "Gin, simple syrup, basil & lemon.",
                        "cost": 80,
                        "id": 5
                    },
                    {
                        "name": "espresso martini",
                        "info": "Vodka, espresso, Kahlua",
                        "cost": 80,
                        "id": 6
                    },
                    {
                        "name": "Valentino",
                        "info": "Frozen or on the rocks, tequila, triple sec, lemon juice, Strawberry, Mango, Pineapple",
                        "cost": "80/440",
                        "id": 7
                    },
                    {
                        "name": "bramble",
                        "info": "Gin, crème de cassis, lemon & simple syrup.",
                        "cost": 80,
                        "id": 8
                    },
                    {
                        "name": "moscow mule",
                        "info": "Vodka, lime, ginger syrup, angostura bitters, soda",
                        "cost": 80,
                        "id": 9
                    }
                ],
                "id": 4
            },
            {
                "subMenu": "from the sea",
                "meals": [
                    {
                        "name": "seafood pastea",
                        "info": "seafood mix, creamy tomato basil, herbs",
                        "cost": 320,
                        "id": 0
                    },
                    {
                        "name": "salmon pasta",
                        "info": "smoked salmon, white wine cream sauce",
                        "cost": 300,
                        "id": 1
                    },
                    {
                        "name": "PAN SEARED GROUPER & SALSA",
                        "info": "mild mango-avocado salsa, coconut rice",
                        "cost": 300,
                        "id": 2
                    },
                    {
                        "name": "tarragon grouper",
                        "info": "market vegetables, tarragon sauce",
                        "cost": 320,
                        "id": 3
                    },
                    {
                        "name": "grilled prawns",
                        "info": "Seasonal prawns, Lemon garlic butter, market vegetables",
                        "cost": 380,
                        "id": 4
                    },
                    {
                        "name": "honey soy salmon",
                        "info": "Norwegian salmon fillet, Honey soy, brocolli, potato, spinach",
                        "cost": 400,
                        "id": 5
                    }
                ],
                "id": 5
            },
            {
                "subMenu": "market sides",
                "meals": [
                    {
                        "name": "green salad",
                        "cost": 45,
                        "id": 0
                    },
                    {
                        "name": "french fries",
                        "cost": 69,
                        "id": 1
                    },
                    {
                        "name": "market vegetables",
                        "cost": 50,
                        "id": 2
                    },
                    {
                        "name": "mashed potatoes",
                        "cost": 50,
                        "id": 3
                    },
                    {
                        "name": "steamed rice / coconut rice",
                        "cost": 50,
                        "id": 4
                    },
                    {
                        "name": "Sautéed Vegetables",
                        "cost": 65,
                        "id": 5
                    },
                    {
                        "name": "sweet potato fries",
                        "cost": 80,
                        "id": 6
                    }
                ],
                "id": 6
            },
            {
                "subMenu": "desserts",
                "meals": [
                    {
                        "name": "sorbet",
                        "info": "2 scoops of your choice",
                        "cost": 80,
                        "id": 0
                    },
                    {
                        "name": "apple crumble",
                        "info": "Cinnamon apple, warm crumbles, caramel sauce",
                        "cost": 110,
                        "id": 1
                    },
                    {
                        "name": "chocolate banana CRÈPE",
                        "info": "Warm chocolate crepe, Nutella, banana, hazelnuts",
                        "cost": 110,
                        "id": 2
                    },
                    {
                        "name": "tropical cheesecake",
                        "info": "Coconut cheesecake, passion fruit & mango coulis",
                        "cost": 110,
                        "id": 3
                    },
                    {
                        "name": "caramel pudding",
                        "info": "Cookie crumbs, caramel Pudding, caramel sauce",
                        "cost": 100,
                        "id": 4
                    },
                    {
                        "name": "carrot cake",
                        "info": "toasted walnuts, cream cheese buttercream",
                        "cost": 95,
                        "id": 5
                    }
                ],
                "id": 7
            },
            {
                "subMenu": "beverages",
                "meals": [
                    {
                        "name": "Soft Drink",
                        "cost": 30,
                        "id": 0
                    },
                    {
                        "name": "fresh juice / juice",
                        "cost": 40,
                        "id": 1
                    },
                    {
                        "name": "energy drink",
                        "cost": 80,
                        "id": 2
                    },
                    {
                        "name": "still water",
                        "info": "small / large",
                        "cost": 0.5,
                        "id": 3
                    },
                    {
                        "name": "sparkling water",
                        "info": "small / large",
                        "cost": "35/55",
                        "id": 4
                    },
                    {
                        "name": "Espresso / Double",
                        "info": "Bourbon Whiskey, angostura bitters & brown sugar.",
                        "cost": "25/45",
                        "id": 5
                    },
                    {
                        "name": "Tea",
                        "cost": 25,
                        "id": 6
                    },
                    {
                        "name": "cappucino",
                        "cost": 45,
                        "id": 7
                    },
                    {
                        "name": "latte",
                        "cost": 45,
                        "id": 8
                    }
                ],
                "id": 8
            }
        ],
        "id": 0
    },
    {
        "_id": 1,
        "value": "wine",
        "viewValue": "Wine",
        "options": [
            {
                "subMenu": "red",
                "meals": [
                    {
                        "id": 0,
                        "name": "La Verriere",
                        "info": "Bordeaux Supérieur, France",
                        "year": 2021,
                        "cost": "100/480",
                        "imgName": "la_verriere"
                    },
                    {
                        "id": 1,
                        "name": "Astoria \"Icóna\"",
                        "info": "Bordeaux Supérieur, France",
                        "year": 2022,
                        "cost": "460",
                        "imgName": "astoria"
                    },
                    {
                        "id": 2,
                        "name": "Castiglioni Chianti",
                        "info": "Frescobaldi- Toscana D.O.C.G, Italy",
                        "year": 2021,
                        "cost": "560",
                        "imgName": "chianti"
                    },
                    {
                        "id": 3,
                        "name": "Fleur du Cap ‘16",
                        "info": "Cabernet Sauvignon, South Africa",
                        "year": 2016,
                        "cost": "560",
                        "imgName": "fleurDuCap"
                    },
                    {
                        "id": 4,
                        "name": "Nippozzano Riserva",
                        "info": "Chianti Rufina - Frescobaldi Toscana D.O.C.G, Italy",
                        "year": 2021,
                        "cost": "960",
                        "imgName": "riserva"
                    },
                    {
                        "id": 5,
                        "name": "Château au Pont de Guitres",
                        "info": "Lalande de Pomerol, France",
                        "year": 2019,
                        "cost": "1'250",
                        "imgName": "guitres"
                    },
                    {
                        "id": 6,
                        "name": "Château Tour de Capet",
                        "info": "Saint–émilion GCC, France",
                        "year": 2018,
                        "cost": "100/480",
                        "imgName": "tour_de_capet"
                    },
                    {
                        "id": 7,
                        "name": "Château Lafont Menaut",
                        "info": "Saint–émilion GCC, France",
                        "year": 2018,
                        "cost": "1'450",
                        "imgName": "lafont"
                    },
                    {
                        "id": 8,
                        "name": "Château Grand Soussans",
                        "info": "Margaux, France",
                        "year": 2019,
                        "cost": "100/480",
                        "imgName": "soussans"
                    }
                ],
                "id": 0
            },
            {
                "subMenu": "white",
                "meals": [
                    {
                        "id": 0,
                        "name": "La Verriere",
                        "info": "Sauvignon blanc, Bordeaux, France",
                        "year": 2023,
                        "cost": "100/480",
                        "imgName": "la_verriere_blanc"
                    },
                    {
                        "id": 1,
                        "name": "19 Crimes Chard",
                        "info": "Victoria, Australia",
                        "year": "",
                        "cost": "450",
                        "imgName": "chard"
                    },
                    {
                        "id": 2,
                        "name": "Astoria \"Suade\"",
                        "info": "Sauvignon blanc, Venezia - Italy",
                        "year": 2023,
                        "cost": "460",
                        "imgName": "suade"
                    },
                    {
                        "id": 3,
                        "name": "Astoria \"Alisia\"",
                        "info": "Pinot Grigio, Venezia - Italy",
                        "year": 2023,
                        "cost": "460",
                        "imgName": "alisia"
                    }
                ],
                "id": 1
            },
            {
                "subMenu": "rosé",
                "meals": [
                    {
                        "id": 0,
                        "name": "Rèmole Rosé",
                        "info": "Sauvignon blanc, Bordeaux, France",
                        "year": 2023,
                        "cost": "100/400",
                        "imgName": "remoleRose"
                    },
                    {
                        "id": 1,
                        "name": "Gerard Bertrand Gris Blanc",
                        "info": "Languedoc - Roussillon, France",
                        "year": "2023",
                        "cost": "450",
                        "imgName": "grisBlanc"
                    },
                    {
                        "id": 2,
                        "name": "Alìe",
                        "info": "Frescobaldi - Toscana I.G.T, Italy",
                        "year": 2022,
                        "cost": "500",
                        "imgName": "alie"
                    },
                    {
                        "id": 3,
                        "name": "Whispering Angel",
                        "info": "Côtes de Provence Rosé - France",
                        "year": 2023,
                        "cost": "800",
                        "imgName": "whisperingAngel"
                    }
                ],
                "id": 2
            },
            {
                "subMenu": "sparkling",
                "meals": [
                    {
                        "id": 0,
                        "name": "Astoria Prosecco \"Tiemo\"",
                        "info": "D.O.C. Brut, Treviso, Italy",
                        "cost": "120/500",
                        "imgName": "tiemo"
                    },
                    {
                        "id": 1,
                        "name": "Astoria Prosecco Rosé \"Velére\"\"",
                        "info": "D.O.C. Brut, Treviso, Italy",
                        "cost": "140/600",
                        "imgName": "velere"
                    },
                    {
                        "id": 2,
                        "name": "Astoria Fashion Victim Moscato",
                        "info": "Sweet Sparkling Wine, Venezia-Italy",
                        "cost": "450",
                        "imgName": "moscato"
                    },
                    {
                        "id": 3,
                        "name": "Astoria Fashion Victim Moscato rosé",
                        "info": "Sweet Sparkling Wine, Venezia-Italy",
                        "cost": "450",
                        "imgName": "moscato_rose"
                    },
                    {
                        "id": 4,
                        "name": "Nippozzano Riserva",
                        "info": "Champagne, France",
                        "cost": "2'400",
                        "imgName": "moet_riserva"
                    },
                    {
                        "id": 5,
                        "name": "Château au Pont de Guitres",
                        "info": "Reims, France",
                        "cost": "2'750",
                        "imgName": "clicquot"
                    },
                    {
                        "id": 6,
                        "name": "Château Tour de Capet",
                        "info": "NV, Épernay, France",
                        "cost": "2'900",
                        "imgName": "chandon_rose"
                    }
                ],
                "id": 3
            }
        ],
        "id": 1
    },
    {
        "_id": 2,
        "value": "beverage",
        "viewValue": "Beverage",
        "options": [
            {
                "subMenu": "classic cocktails",
                "meals": [
                    {
                        "name": "Tommy's magarita",
                        "info": "100% Agave blanco tequila, fresh lime, agave nectar",
                        "cost": 80,
                        "id": 0
                    },
                    {
                        "name": "Caipirinha",
                        "info": "Cachaça, brown sugar, fresh lime",
                        "cost": 80,
                        "id": 1
                    },
                    {
                        "name": "mai tai",
                        "info": "White rum, dark rum, apricot brandy, orange juice, lemon juice, lime syrup",
                        "cost": 80,
                        "id": 2
                    },
                    {
                        "name": "Old fashioned",
                        "info": "Bourbon Whiskey, angostura bitters & brown sugar.",
                        "cost": 80,
                        "id": 3
                    },
                    {
                        "name": "Valentino",
                        "info": "Vodka, watermelon liqueur, basil & cucumber",
                        "cost": 80,
                        "id": 4
                    },
                    {
                        "name": "gin basil",
                        "info": "Gin, simple syrup, basil & lemon.",
                        "cost": 80,
                        "id": 5
                    },
                    {
                        "name": "espresso martini",
                        "info": "Vodka, espresso, Kahlua",
                        "cost": 80,
                        "id": 6
                    },
                    {
                        "name": "Valentino",
                        "info": "Frozen or on the rocks, tequila, triple sec, lemon juice, Strawberry, Mango, Pineapple",
                        "cost": "80/440",
                        "id": 7
                    },
                    {
                        "name": "bramble",
                        "info": "Gin, crème de cassis, lemon & simple syrup.",
                        "cost": 80,
                        "id": 8
                    },
                    {
                        "name": "moscow mule",
                        "info": "Vodka, lime, ginger syrup, angostura bitters, soda",
                        "cost": 80,
                        "id": 9
                    }
                ],
                "id": 0
            }
        ],
        "id": 2
    }
]

// export const menuOptions = [
//     {
//         "_id": 0,
//         "value": "food",
//         "viewValue": "Food",
//         "options": [
//             {
//                 "subMenu": "starters",
//                 "meals": [
//                     {
//                         "name": "BUTTERNUT SQUASH SOUP",
//                         "info": "goat cheese sprinkle",
//                         "cost": 100,
//                         "id": 0
//                     },
//                     {
//                         "name": "crunchy chicken",
//                         "info": "breaded crust, sweet chili dip",
//                         "cost": 125,
//                         "id": 1
//                     },
                  
                    
                  
//                 ],
//                 "id": 0
//             },
//             {
//                 "subMenu": "gourmet burgers",
//                 "meals": [
//                     {
//                         "name": "The original",
//                         "info": "200g Beef patty, lettuce, tomato, onions, pickles, mature cheddar, classic sauce",
//                         "cost": 220,
//                         "id": 0
//                     },
//                     {
//                         "name": "the chicken",
//                         "info": "Grilled chicken, lettuce, pickles, mature cheddar, onion rings, house-made barbecue sauce",
//                         "cost": 195,
//                         "id": 1
//                     },
                   
//                 ],
//                 "id": 1
//             },
//             {
//                 "subMenu": "salads",
//                 "meals": [
//                     {
//                         "name": "caesar",
//                         "info": "Romaine, grated reggiano, croutons, Caesar dressing (chicken +45, Shrimps +65)",
//                         "cost": 175,
//                         "id": 0
//                     },
                  
                    
//                 ],
//                 "id": 2
//             },
//             {
//                 "subMenu": "sandwiches (lunch only)",
//                 "meals": [
//                     {
//                         "name": "Tfalafel wrap",
//                         "info": "Flour tortilla, chickpeas, fava Beans, tomato, arugula, radish, spicy sesame sauce",
//                         "cost": 155,
//                         "id": 0
//                     },
                    
//                 ],
//                 "id": 3
//             },
//             {
//                 "subMenu": "from the land",
//                 "meals": [
//                     {
//                         "name": "Tommy's magarita",
//                         "info": "100% Agave blanco tequila, fresh lime, agave nectar",
//                         "cost": 80,
//                         "id": 0
//                     },
//                     {
//                         "name": "Caipirinha",
//                         "info": "Cachaça, brown sugar, fresh lime",
//                         "cost": 80,
//                         "id": 1
//                     },
//                     {
//                         "name": "mai tai",
//                         "info": "White rum, dark rum, apricot brandy, orange juice, lemon juice, lime syrup",
//                         "cost": 80,
//                         "id": 2
//                     },
                   
                    
//                 ],
//                 "id": 4
//             },
//             {
//                 "subMenu": "from the sea",
//                 "meals": [
//                     {
//                         "name": "seafood pastea",
//                         "info": "seafood mix, creamy tomato basil, herbs",
//                         "cost": 320,
//                         "id": 0
//                     },
//                     {
//                         "name": "salmon pasta",
//                         "info": "smoked salmon, white wine cream sauce",
//                         "cost": 300,
//                         "id": 1
//                     },
//                     {
//                         "name": "PAN SEARED GROUPER & SALSA",
//                         "info": "mild mango-avocado salsa, coconut rice",
//                         "cost": 300,
//                         "id": 2
//                     },
                  
//                 ],
//                 "id": 5
//             },
//             {
//                 "subMenu": "market sides",
//                 "meals": [
//                     {
//                         "name": "green salad",
//                         "cost": 45,
//                         "id": 0
//                     },
//                     {
//                         "name": "french fries",
//                         "cost": 69,
//                         "id": 1
//                     },
//                     {
//                         "name": "market vegetables",
//                         "cost": 50,
//                         "id": 2
//                     },
                   
//                 ],
//                 "id": 6
//             },
//             {
//                 "subMenu": "desserts",
//                 "meals": [
//                     {
//                         "name": "sorbet",
//                         "info": "2 scoops of your choice",
//                         "cost": 80,
//                         "id": 0
//                     },
//                     {
//                         "name": "apple crumble",
//                         "info": "Cinnamon apple, warm crumbles, caramel sauce",
//                         "cost": 110,
//                         "id": 1
//                     },
                 
//                 ],
//                 "id": 7
//             },
//             {
//                 "subMenu": "beverages",
//                 "meals": [
//                     {
//                         "name": "Soft Drink",
//                         "cost": 30,
//                         "id": 0
//                     },
//                     {
//                         "name": "fresh juice / juice",
//                         "cost": 40,
//                         "id": 1
//                     },
//                     {
//                         "name": "energy drink",
//                         "cost": 80,
//                         "id": 2
//                     },
              
//                 ],
//                 "id": 8
//             }
//         ],
//         "id": 0
//     },
//     {
//         "_id": 1,
//         "value": "wine",
//         "viewValue": "Wine",
//         "options": [
//             {
//                 "subMenu": "red",
//                 "meals": [
//                     {
//                         "id": 0,
//                         "name": "La Verriere",
//                         "info": "Bordeaux Supérieur, France",
//                         "year": 2021,
//                         "cost": "100/480",
//                         "imgName": "la_verriere"
//                     },
//                     {
//                         "id": 1,
//                         "name": "Astoria \"Icóna\"",
//                         "info": "Bordeaux Supérieur, France",
//                         "year": 2022,
//                         "cost": "460",
//                         "imgName": "astoria"
//                     },
                    
//                 ],
//                 "id": 0
//             },
//             {
//                 "subMenu": "white",
//                 "meals": [
//                     {
//                         "id": 0,
//                         "name": "La Verriere",
//                         "info": "Sauvignon blanc, Bordeaux, France",
//                         "year": 2023,
//                         "cost": "100/480",
//                         "imgName": "la_verriere_blanc"
//                     },
//                     {
//                         "id": 1,
//                         "name": "19 Crimes Chard",
//                         "info": "Victoria, Australia",
//                         "year": "",
//                         "cost": "450",
//                         "imgName": "chard"
//                     },
                
//                 ],
//                 "id": 1
//             },
//             {
//                 "subMenu": "rosé",
//                 "meals": [
//                     {
//                         "id": 0,
//                         "name": "Rèmole Rosé",
//                         "info": "Sauvignon blanc, Bordeaux, France",
//                         "year": 2023,
//                         "cost": "100/400",
//                         "imgName": "remole-rose"
//                     },
//                     {
//                         "id": 1,
//                         "name": "Gerard Bertrand Gris Blanc",
//                         "info": "Languedoc - Roussillon, France",
//                         "year": "2023",
//                         "cost": "450",
//                         "imgName": "gris-blanc"
//                     },
                   
//                 ],
//                 "id": 2
//             },
//             {
//                 "subMenu": "sparkling",
//                 "meals": [
//                     {
//                         "id": 0,
//                         "name": "Astoria Prosecco \"Tiemo\"",
//                         "info": "D.O.C. Brut, Treviso, Italy",
//                         "cost": "120/500",
//                         "imgName": "tiemo"
//                     },
//                     {
//                         "id": 1,
//                         "name": "Astoria Prosecco Rosé \"Velére\"\"",
//                         "info": "D.O.C. Brut, Treviso, Italy",
//                         "cost": "140/600",
//                         "imgName": "velere"
//                     },
                   
                    
//                 ],
//                 "id": 3
//             }
//         ],
//         "id": 1
//     },
//     {
//         "_id": 2,
//         "value": "beverage",
//         "viewValue": "Beverage",
//         "options": [
//             {
//                 "subMenu": "classic cocktails",
//                 "meals": [
//                     {
//                         "name": "Tommy's magarita",
//                         "info": "100% Agave blanco tequila, fresh lime, agave nectar",
//                         "cost": 80,
//                         "id": 0
//                     },
//                     {
//                         "name": "Caipirinha",
//                         "info": "Cachaça, brown sugar, fresh lime",
//                         "cost": 80,
//                         "id": 1
//                     },
                    
                    
//                 ],
//                 "id": 0
//             }
//         ],
//         "id": 2
//     }
// ]