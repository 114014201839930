import la_verriere  from "./../../images/menu/wine/la_verriere.png" 
import astoria  from "./../../images/menu/wine/astoria.png" 
import chianti  from "./../../images/menu/wine/chianti.png" 
import fleurDuCap  from "./../../images/menu/wine/fleurDuCap.png" 
import riserva  from "./../../images/menu/wine/riserva.png" 
import guitres  from "./../../images/menu/wine/guitres.png" 
import tour_de_capet  from "./../../images/menu/wine/tour_de_capet.png" 
import lafont  from "./../../images/menu/wine/lafont.png" 
import soussans  from "./../../images/menu/wine/soussans.png" 
import la_verriere_blanc  from "./../../images/menu/wine/la_verriere_blanc.png" 
import chard  from "./../../images/menu/wine/chard.png" 
import suade  from "./../../images/menu/wine/suade.png" 
import alisia  from "./../../images/menu/wine/alisia.png" 
import remoleRose  from "./../../images/menu/wine/remoleRose.png" 
import grisBlanc  from "./../../images/menu/wine/grisBlanc.png" 
import alie  from "./../../images/menu/wine/alie.png" 
import whisperingAngel  from "./../../images/menu/wine/whisperingAngel.png" 
import tiemo  from "./../../images/menu/wine/tiemo.png" 
import velere  from "./../../images/menu/wine/velere.png" 
import moscato  from "./../../images/menu/wine/moscato.png" 
import moscato_rose  from "./../../images/menu/wine/moscato_rose.png" 
import moet_riserva  from "./../../images/menu/wine/moet_riserva.png" 
import clicquot  from "./../../images/menu/wine/clicquot.png" 
import chandon_rose  from "./../../images/menu/wine/chandon_rose.png" 


const wineImages = {
    la_verriere,
    astoria,
    chianti,
    fleurDuCap, 
    riserva,
    guitres,
    tour_de_capet,
    lafont,
    soussans,
    la_verriere_blanc,
    chard,
    suade,
    alisia,
    remoleRose,
    grisBlanc,
    alie,
    whisperingAngel,
    tiemo,
    velere,
    moscato,
    moscato_rose,
    moet_riserva,
    clicquot,
    chandon_rose
}

export default wineImages