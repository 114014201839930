import {React, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import "./style.css";

import img404r from "./../../images/Group 4-raw.png"
import Header from "../header";
import Footer from "../footer";

const ErrorPage = () => {

  return (
    <div className="error-page">
      <Header/>

      <div className="error-wrapper">
          <div className="error-content">              
              <div className="error-container" >
                <div className="error-content">
                  <p className="err-title poppins-bold"> Oops...</p>
                  <p className="err-sub-title poppins-semibold"> Page not found</p>
                  <p className="err-text poppins-regular">Sorry, the page you are looking for does not exist.</p>
                </div>
                <div className="error-img-container">
                  <div className="logo">
                    <img
                      src={img404r}
                      alt="rose gardens logo"
                      // width="150px"
                    />
                  </div>                
                </div>
              </div>                                                
          </div>
      </div>

      <Footer/>
    </div>
  );
};

export default ErrorPage;
