import React, { useState } from "react";
import "./style.css";
import prevIcon from "./../../images/prev-icon.svg"
import nextIcon from "./../../../src/images/next-icon.svg"

const MenuSlider = ({ options }) => {
  const [currentIndex, setCurrentIndex] = useState(0); // Track the starting index of visible items

  const itemsPerView = 5; // Adjust the number of visible items in the list
  const maxIndex = Math.max(0, options.length - itemsPerView);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const scrollToSubMenu = (subMenuId) => {
    document.getElementById(`submenu-${subMenuId}`)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const slice = (arg) => (arg.length > 15) ? arg.slice(0, 15) +"..." : arg;
  

  return (
    <div className="slider-container">
      <div className="btn-container prev" onClick={handlePrev}>
         <img src={prevIcon} alt="previous icon" style={{display: currentIndex === 0  ? 'none' : 'block'}}/>
      </div>
      <div className="menu-slider">
        <ul className="menu-list">
          {options.map((item, index) => (
            <li
              key={item.id}
              className={`menu-item ${
                index >= currentIndex && index < currentIndex + itemsPerView
                  ? "visible"
                  : "hidden"
              }`}
              onClick={() => scrollToSubMenu(item.subMenu)}

            >
              {slice(item.subMenu) || "No SubMenu"}
            </li>
          ))}
        </ul>
      </div>
      <div className="btn-container next" onClick={handleNext}>
          <img src={nextIcon} alt="next icon" style={{display: currentIndex === maxIndex  ? 'none' : 'block'}}/>
      </div>
    </div>
  );
};

export default MenuSlider;
