import {React, useState} from 'react'
import './style.css'
import Header from '../header';
import Banner from '../banner';
import Slider from './slider';
import food from './../../images/slider/Food.jpeg'
import pizza from './../../images/slider/assorted-foodset-on-table-pizza-600nw-1437909707.webp'
import chicken from './../../images/slider/Easy-Chinese-Chicken-photo-e1534681035936-1080x675.webp'
import ig_snapshot from './../../images/slider/img-ig.png'
import Footer from '../footer';

const  AboutUs = () => {
  const imageArr = [food, pizza, chicken, ig_snapshot];
  const [activePage, setActivePage] = useState("about-us");

  return (
    <div className='about-wrapper'>
      <Header activePageStr={activePage}/>
      <Banner/>
      <div className='about-container'>
        <Slider images = {imageArr}/>
        <div className='about-us-content'>
          <div className='title'>
            <p className='poppins-bold'>about us</p>
          </div>
          <div className='about-msg'>
            <p className="poppins-regular">
              Nestled in the heart of Accra, Ghana, 
              Rosé Garden is a charming breakfast 
              restaurant located at 30 Orphan Cres. 
              With its cozy and inviting atmosphere, 
              it’s the perfect spot to start your day 
              with a delicious meal.
            </p>
          </div>
          <div className="about-working-hours">
            <h4  className="about-working-hour-title poppins-bold">Opening Hours</h4>
            <div className="hours">
                <div>
                    <p><strong>Tuesday - Friday:</strong></p>
                    <p>8:00 am to 5:00 pm</p>
                </div>
                <div>
                    <p><strong>Saturday - Sunday:</strong> </p>
                    <p>8:00 am to 6:00 pm</p>
                </div>
                <div>
                    <p><strong>Location:</strong></p>
                    <p>30 Orphan Cres, Labone, Accra</p>
                </div>  
                
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default AboutUs